import Link from 'next/link';
import styled from 'styled-components';
import { slugify } from 'underscore.string';
import { useSelector } from 'src/store/store';
import i18n from 'src/utils/translate';
import PostLink from 'src/modules/shared/links/postLink';
import PinIcon from 'src/modules/community/communityHome/components/pinIcon';
import { ResultsList, ResultsPost, ResultsPostTitle, ResultsPostBody } from 'styles-js/resultsList';
import { textSmall } from 'styles-js/mixins/typography';
import { PostType } from 'src/constants/types';
export default function StickyPinnedPost() {
  const communitySlug = useSelector(({
    context
  }) => context.currentCommunity?.slug);
  const stickyPost = useSelector(({
    pages
  }) => pages.communityHome.stickyPost);
  if (!stickyPost?.title || !stickyPost?.postId || !communitySlug) return null;
  return <Container data-sentry-element="Container" data-sentry-component="StickyPinnedPost" data-sentry-source-file="stickyPinnedPost.tsx">
      <ResultsList data-sentry-element="ResultsList" data-sentry-source-file="stickyPinnedPost.tsx">
        <Badge data-sentry-element="Badge" data-sentry-source-file="stickyPinnedPost.tsx">
          <PinIcon data-sentry-element="PinIcon" data-sentry-source-file="stickyPinnedPost.tsx" />
          {i18n.t('Pinned')}
        </Badge>
        <ResultsPost data-sentry-element="ResultsPost" data-sentry-source-file="stickyPinnedPost.tsx">
          <PostLink communitySlug={communitySlug} isPrivate={false} postId={stickyPost.postId} postTypeId={PostType.Post} urlEncodedTitle={slugify(stickyPost.title)} data-sentry-element="PostLink" data-sentry-source-file="stickyPinnedPost.tsx">
            <ResultsPostTitle data-sentry-element="ResultsPostTitle" data-sentry-source-file="stickyPinnedPost.tsx">{stickyPost.title}</ResultsPostTitle>
            <ResultsPostBody data-sentry-element="ResultsPostBody" data-sentry-source-file="stickyPinnedPost.tsx">{stickyPost.bodySnippet}</ResultsPostBody>
          </PostLink>
        </ResultsPost>
        <Link legacyBehavior passHref as={`/${communitySlug}/posts#pinned`} href={`/community/communityPosts?communitySlug=${communitySlug}`} data-sentry-element="Link" data-sentry-source-file="stickyPinnedPost.tsx">
          <SeeAllPinnedPostsLink data-sentry-element="SeeAllPinnedPostsLink" data-sentry-source-file="stickyPinnedPost.tsx">{i18n.t('See all pinned posts')}{' >'}</SeeAllPinnedPostsLink>
        </Link>
      </ResultsList>
    </Container>;
}
const Container = styled.div`
  position: relative;
`;
const Badge = styled.div`
  ${textSmall}
  position: absolute;
  top: -11px;
  right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 2px 7px;
  color: ${({
  theme
}) => theme.colorWhite};
  background-color: ${({
  theme
}) => theme.colorBlack};
  border-radius: 15px;

  svg {
    width: 10px;
    height: 11px;
  }
  svg path {
    fill: ${({
  theme
}) => theme.colorWhite};
  }
`;
const SeeAllPinnedPostsLink = styled.a`
  display: block;
  padding: 10px 25px;
  border-top: ${({
  theme
}) => theme.greyLine};
  @media (${({
  theme
}) => theme.underScreenSmall}) {
    padding: 10px 20px;
  }
`;