import Link from 'next/link';
import styled from 'styled-components';
import { useSelector } from 'src/store/store';
import i18n from 'src/utils/translate';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event, Section } from 'src/constants/footfall';
import { useFetchFeaturedPosts } from 'src/utils/featuredPosts';
import PostItem from 'src/modules/shared/resultsList/components/postItem';
import FeaturedPost from 'src/modules/community/shared/components/featuredPosts/featuredPost';
import CommunityFooterCTA from 'src/modules/community/shared/components/communityFooterCTA';
import PostListLoader from 'src/modules/shared/resultsList/components/postListLoader';
import PostFilterDropdown from 'src/modules/community/communityHome/components/postFilterDropdown';
import KeepReading from 'src/modules/community/communityHome/components/keepReading';
import Icon from 'src/components/icon';
import StickyPinnedPost from 'src/modules/community/communityHome/components/stickyPinnedPost';
import { ResultsSeeMoreCTA, ResultsList, EmptyResults } from 'styles-js/resultsList';
import { titleSmall } from 'styles-js/mixins/typography';
import { PinnedPostsWidget } from 'src/constants/types';
export default function CommunityHomeContent() {
  const currentCommunity = useSelector(({
    context
  }) => context.currentCommunity);
  const {
    filter,
    widgets,
    isPostListLoading,
    posts,
    stickyPost
  } = useSelector(({
    pages
  }) => pages.communityHome);
  const [featuredPostTop, featuredPostBottom] = useFetchFeaturedPosts();
  const pinnedPostsWidget = widgets.find(({
    widgetType
  }) => widgetType === 'PinnedPosts');
  const isPinnedPostsLinkEnabled = !stickyPost?.title && (pinnedPostsWidget as PinnedPostsWidget)?.posts?.length > 0;
  return <>
      <StickyPinnedPost data-sentry-element="StickyPinnedPost" data-sentry-source-file="communityHomeContent.tsx" />
      {isPinnedPostsLinkEnabled && <Link legacyBehavior passHref as={`/${currentCommunity?.slug}/posts#pinned`} href={`/community/communityPosts?communitySlug=${currentCommunity?.slug}`}>
          <PinnedPostsLink onClick={() => {
        trackFootfallEvent(Event.Clicked, {
          clickType: 'pinned-posts',
          clickMetadata: {
            section: Section.MobileShortcut
          }
        });
      }}>
            <div>{i18n.t('View pinned posts')}</div>
            <Icon icon="open-right" />
          </PinnedPostsLink>
        </Link>}
      <KeepReading data-sentry-element="KeepReading" data-sentry-source-file="communityHomeContent.tsx" />
      <Filters data-sentry-element="Filters" data-sentry-source-file="communityHomeContent.tsx">
        <PostFilterDropdown filter={filter} data-sentry-element="PostFilterDropdown" data-sentry-source-file="communityHomeContent.tsx" />
      </Filters>
      {isPostListLoading ? <PostListLoader /> : posts.length > 0 ? <ResultsList>
            <PostItem community={currentCommunity} isSearchPost={false} post={posts[0]} />
            {featuredPostTop && <FeaturedPostContainer>
                <FeaturedPost featuredPost={featuredPostTop} key={featuredPostTop.featuredPostId} position="home-top" />
              </FeaturedPostContainer>}
            <PostItem community={currentCommunity} isSearchPost={false} post={posts[1]} />
            <PostItem community={currentCommunity} isSearchPost={false} post={posts[2]} />
            <PostItem community={currentCommunity} isSearchPost={false} post={posts[3]} />
            {featuredPostBottom && <FeaturedPostContainer>
                <FeaturedPost featuredPost={featuredPostBottom} key={featuredPostBottom.featuredPostId} position="home-bottom" />
              </FeaturedPostContainer>}
            <PostItem community={currentCommunity} isSearchPost={false} post={posts[4]} />
            <ResultsSeeMoreCTA>
              <Link as={`/${currentCommunity?.slug}/posts`} href={`/community/communityPosts?communitySlug=${currentCommunity?.slug}`} onClick={() => trackFootfallEvent(Event.Clicked, {
          clickType: 'see-all-posts',
          clickMetadata: {
            section: Section.CommunityHome
          }
        })}>
                {i18n.t('See all posts')} <Icon icon="open-right" />
              </Link>
            </ResultsSeeMoreCTA>
          </ResultsList> : !currentCommunity?.isArchived && <EmptyResults>
              <span>
                {i18n.t('Nothing here yet! Be the first to ')}
                <Link as={`/${currentCommunity?.slug}/posts/write`} href={`/community/write?communitySlug=${currentCommunity?.slug}`}>
                  {i18n.t('share your experience')}
                </Link>
                !
              </span>
            </EmptyResults>}
      <CommunityFooterCTA data-sentry-element="CommunityFooterCTA" data-sentry-source-file="communityHomeContent.tsx" />
    </>;
}
const PinnedPostsLink = styled.a`
  padding: 15px;
  display: flex;
  gap: 10px;
  border-bottom: ${({
  theme
}) => theme.greyLine};
  ${titleSmall}
  @media (${({
  theme
}) => theme.overScreenSmall}) {
    display: none;
  }
`;
const Filters = styled.div`
  @media (${({
  theme
}) => theme.underScreenSmall}) {
    padding: 15px;
  }
  .dropdown-toggle {
    ${titleSmall}
    padding: 0;
  }
  .dropdown-menu {
    line-height: 35px;
  }
`;
const FeaturedPostContainer = styled.div`
  padding: 25px;
  border-bottom: ${({
  theme
}) => theme.greyLine};
  @media (${({
  theme
}) => theme.underScreenSmall}) {
    padding: 20px;
  }
`;