import Link from 'next/link';
import styled from 'styled-components';
import { slugify } from 'underscore.string';
import { useSelector } from 'src/store/store';
import i18n from 'src/utils/translate';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event, Element, Section } from 'src/constants/footfall';
import config from 'config/config';
import Card from 'src/components/card';
import Icon from 'src/components/icon';
import PostLink from 'src/modules/shared/links/postLink';
import { titleSmall } from 'styles-js/mixins/typography';
import { ButtonBase, SecondaryButtonStyles } from 'styles-js/buttons';
import { PostType, LatestPollWidget } from 'src/constants/types';
type Props = {
  widget: LatestPollWidget;
};
export default function CommunityLatestPoll({
  widget
}: Props) {
  const communitySlug = useSelector(({
    context
  }) => context.currentCommunity?.slug);
  if (!widget?.pollId || !communitySlug) return null;
  const onClick = (element: Element) => {
    trackFootfallEvent(Event.Clicked, {
      clickType: 'community-latest-poll',
      clickMetadata: {
        section: Section.CommunitySideBar,
        element
      }
    });
  };
  return <Card seeAllLink={<SeeAllLink slug={communitySlug} />} title={i18n.t('Latest poll')} data-sentry-element="Card" data-sentry-component="CommunityLatestPoll" data-sentry-source-file="communityLatestPoll.tsx">
      <CardHeading data-sentry-element="CardHeading" data-sentry-source-file="communityLatestPoll.tsx">
        <IconWrapper data-sentry-element="IconWrapper" data-sentry-source-file="communityLatestPoll.tsx">
          <PollGraphic alt="Poll icon" src={`${config.cdn.static}img/icon-poll.png`} data-sentry-element="PollGraphic" data-sentry-source-file="communityLatestPoll.tsx" />
        </IconWrapper>
        <Heading data-sentry-element="Heading" data-sentry-source-file="communityLatestPoll.tsx">
          <PostLink communitySlug={communitySlug} isPrivate={false} postId={Number(widget.pollId)} postTypeId={PostType.Poll} urlEncodedTitle={slugify(widget.title)} onClick={() => onClick(Element.PollTitle)} data-sentry-element="PostLink" data-sentry-source-file="communityLatestPoll.tsx">
            {widget.title}
          </PostLink>
        </Heading>
      </CardHeading>
      <StyledPostLink communitySlug={communitySlug} isPrivate={false} postId={Number(widget.pollId)} postTypeId={PostType.Poll} urlEncodedTitle={slugify(widget.title)} onClick={() => onClick(Element.PollButton)} data-sentry-element="StyledPostLink" data-sentry-source-file="communityLatestPoll.tsx">
        {i18n.t('Vote')}
      </StyledPostLink>
    </Card>;
}
function SeeAllLink({
  slug
}: {
  slug: string;
}) {
  return <Link as={`/${slug}/polls`} href={`/community/communityPosts?communitySlug=${slug}`} shallow={true} onClick={() => {
    window.scrollTo(0, 0);
    trackFootfallEvent(Event.Clicked, {
      clickType: 'community-see-all-polls',
      clickMetadata: {
        section: Section.CommunitySideBar
      }
    });
  }} data-sentry-element="Link" data-sentry-component="SeeAllLink" data-sentry-source-file="communityLatestPoll.tsx">
      {i18n.t('See all polls')} <Icon icon="open-right" data-sentry-element="Icon" data-sentry-source-file="communityLatestPoll.tsx" />
    </Link>;
}
const CardHeading = styled.div`
  display: flex;
  margin-bottom: 10px;
`;
const PollGraphic = styled.img`
  width: 100%;
  height: 100%;
`;
const IconWrapper = styled.div`
  flex-shrink: 0;
  width: 30px;
  height: auto;
  margin: 0 10px 4px 5px;
`;
const Heading = styled.h3`
  margin-bottom: 0;
  &, a {
    ${titleSmall};
  }
  a:hover, a:focus, a:active {
    color: ${props => props.theme.colorGreyDark};
  }
`;
const StyledPostLink = styled(PostLink)`
  ${ButtonBase};
  ${SecondaryButtonStyles};
  margin: 0;
`;