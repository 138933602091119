import styled from 'styled-components';
import { generatePromoImageLink, prepLink } from 'src/utils/linkUtils';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event, Section } from 'src/constants/footfall';
import Card from 'src/components/card';
import { CampaignWidget } from 'src/constants/types';
type Props = {
  widget: CampaignWidget;
};
export default function CommunityHomePromoImage({
  widget
}: Props) {
  const imageUrl = generatePromoImageLink(widget.imageHash);
  const linkUrl = prepLink(widget.url);
  return <Card data-sentry-element="Card" data-sentry-component="CommunityHomePromoImage" data-sentry-source-file="communityHomePromoImage.tsx">
      <A aria-label="community promotion" href={linkUrl} rel="noreferrer" style={{
      backgroundImage: `url("${imageUrl}")`
    }} target="_blank" title="community promotion" onClick={() => {
      trackFootfallEvent(Event.Clicked, {
        clickType: 'community-promo-image',
        clickMetadata: {
          section: Section.CommunitySideBar
        }
      });
    }} data-sentry-element="A" data-sentry-source-file="communityHomePromoImage.tsx" />
    </Card>;
}
const promoImageSize = '378px';
const A = styled.a`
  display: block;
  border: ${({
  theme
}) => theme.greyLine};
  border-radius: 6px;
  background: no-repeat center;
  background-size: cover;
  aspect-ratio: 1;

  @media (max-width: ${({
  theme
}) => theme.screenXsMax}) and (min-width: ${promoImageSize}) {
    width: ${promoImageSize};
    height: ${promoImageSize};
    margin: auto;
    border-radius: 0;
  };
`;