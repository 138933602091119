import Link from 'next/link';
import { Fragment } from 'react';
import { HIDDEN_USERNAME } from 'src/constants/hiddenUser';

// Return an inline links to the user's profile
export const getMention = ({
  userId,
  username
}: {
  userId: number;
  username: string;
}, editable = false, isLoggedIn = true) => {
  if (!isLoggedIn) {
    return <Fragment key={userId}>{username + ' '}</Fragment>;
  }
  const onClick = () => {
    if (username !== HIDDEN_USERNAME) return;
    window.HelpScoutBeacon.triggerHelpScoutArticle('173-what-are-hidden-accounts');
  };
  return (
    // editor needs tabindex -1 else sometimes during delete, (only mention left in), caret gets stuck on the span and can't acquire focus
    <span data-link-container={true} key={userId} tabIndex={editable ? -1 : undefined} data-sentry-component="getMention" data-sentry-source-file="getMention.tsx">
      {editable // needs to be button in editor, with <a>, deleting backwards can have issues in Chrome
      ? <button contentEditable="false" data-id={userId} tabIndex={-1}>{username}</button> : <Link as={`/user/${username}`} data-id={userId} href={`/user/profile?username=${username}`} onClick={onClick}>
            {username + ' '}
          </Link>}
    </span>
  );
};