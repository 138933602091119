import { useEffect } from 'react';
import { useDispatch, useSelector } from 'src/store/store';
import { fetchPostsWithFilter } from 'src/modules/community/communityHome/actions';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event } from 'src/constants/footfall';

export function useFetchPosts() {
  const dispatch = useDispatch();
  const communitySlug = useSelector(({ context }) => context.currentCommunity?.slug);
  const isCommunityMemberOrHUStaff = useSelector(({ context }) => context.currentUser?.isCommunityMemberOrHUStaff);
  const isLoggedIn = useSelector(({ context }) => !!context.currentUser);

  useEffect(() => {
    if (!communitySlug) return;
    // [PROD-1432] [BB-1787] We want to make sure that the default filter is set correctly after the user logs in or followed community
    dispatch(fetchPostsWithFilter({ communitySlug, isLoggedIn }));
  }, [isCommunityMemberOrHUStaff, dispatch, communitySlug, isLoggedIn]);
}

export function useFootfall() {
  const communitySlug = useSelector(({ context }) => context.currentCommunity?.slug);

  useEffect(() => {
    if (communitySlug) {
      trackFootfallEvent(Event.ViewedCommunity, { communityPath: communitySlug });
    }
  }, [communitySlug]);
}
